import {cloneDeep} from "lodash-es";
import {computed, nextTick, onUpdated, Ref, ref, unref} from "vue";
import {useAsyncResponse} from "@core/composable/useAsyncCallback.ts";
import {AxiosResponse} from "axios";

export const events = ['update:modelValue', 'saved', 'canceled'];

const useFormContext = <T, E>(
    modelValue: Ref<T | undefined>,
    saveRequest: (payload: any) => Promise<AxiosResponse>,
    newInstance: () => T,
    emit: (event: string, ...args: any[]) => void,
    saveInstance: ((instance: T) => any) | undefined | null = null,
) => {
    const {loading: saving, execute, error} = useAsyncResponse();
    const initModel = () => modelValue.value ? cloneDeep(modelValue.value) : newInstance();
    const model = ref(initModel()) as Ref<T>;
    const errors = computed<E>(() => {
        return unref(error)?.errors ?? {};
    });

    function payLoad() {
        if (saveInstance) {
            return saveInstance(unref(model));
        } else {
            return unref(model);
        }
    }

    async function reset() {
        await nextTick();
        model.value = initModel();
        error.value = null;
    }

    async function cancel() {
        await reset();
        emit('canceled');
    }

    async function save() {
        await execute(
            saveRequest(payLoad()),
            (data: any) => {
                emit('update:modelValue', data);
                emit('saved', data);
                reset();
            });
    }

    onUpdated(() => {
        model.value = initModel();
    });

    return {
        save,
        saving,
        cancel,
        model,
        errors,
        emit,
    }
}
export default useFormContext;
